<form #settingsForm="ngForm" class="settings-form-container">
  <mat-form-field>
    <input matInput type="text" name="username" placeholder="Username" required [(ngModel)]="data.username" />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="password" name="password" placeholder="Password" required [(ngModel)]="data.password" />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" name="baseUri" placeholder="Base URI" required [(ngModel)]="data.baseUri" />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" name="clientId" placeholder="Client ID" required [(ngModel)]="data.clientId" />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="text" name="semanticTopic" placeholder="Semantic Topic" required [(ngModel)]="data.semanticTopic" />
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" name="refreshInterval" placeholder="Refresh Interval (seconds)" required [(ngModel)]="data.refreshInterval" min="1" max="20" />
  </mat-form-field>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data" [disabled]="settingsForm.invalid">Ok</button>
    <button mat-button [mat-dialog-close]>Cancel</button>
  </div>
</form>
